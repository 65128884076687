import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; 
import { FaUtensils, FaUsers, FaClipboardCheck } from 'react-icons/fa';

const Home = () => {
  const [videoError, setVideoError] = useState(false);

  return (
    <div className="home-container">
      {/* Hero Section with Video Background and Image Fallback */}
      <section className="hero" style={videoError ? {backgroundImage: 'url("https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80")', backgroundSize: 'cover', backgroundPosition: 'center'} : {}}>
        {!videoError && (
          <video 
            className="hero-video" 
            autoPlay 
            loop 
            muted 
            playsInline
            onError={() => setVideoError(true)} 
          >
            <source 
              src="https://assets.mixkit.co/videos/preview/mixkit-top-view-of-different-types-of-food-being-cooked-16908-large.mp4" 
              type="video/mp4"
            />
          </video>
        )}
        <div className="hero-content">
          <h1 className="hero-title">Where Taste Meets Elegance</h1>
          <p className="hero-subtitle">Indulge in a culinary experience like no other.</p>
          <Link to="/menu">
            <button className="btn hero-btn">Discover Our Menu</button>
          </Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2 className="features-title">Why Zubaida Catering?</h2>
        <div className="features-description">
          <p className="feature-point">
            <span className="check-icon">✓</span> Unmatched Quality & Quantity – We never compromise on the taste, freshness, or portion sizes of our food.
          </p>
          
          <p className="feature-point">
            <span className="check-icon">✓</span> Our Special Biryani – A signature dish that has earned us a reputation as the top choice for biryani lovers.
          </p>
          
          <p className="feature-point">
            <span className="check-icon">✓</span> Tailored Experiences – We work closely with our clients, not just to meet but to exceed their expectations.
          </p>
        </div>
        <div className="feature-cards">
          <div className="feature-card">
            <div className="feature-image-container">
              <img 
                src="https://images.unsplash.com/photo-1600565193348-f74bd3c7ccdf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGluZGlhbiUyMGZvb2R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60" 
                alt="Fresh Ingredients" 
                className="feature-image"
              />
            </div>
            <div className="feature-icon">
              <FaUtensils />
            </div>
            <h3>Fresh Ingredients</h3>
            <p>Our commitment to quality starts with the finest ingredients.</p>
          </div>
          <div className="feature-card">
            <div className="feature-image-container">
              <img 
                src="https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGluZGlhbiUyMGNhdGVyaW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" 
                alt="Excellent Service" 
                className="feature-image"
              />
            </div>
            <div className="feature-icon">
              <FaUsers />
            </div>
            <h3>Exceptional Service</h3>
            <p>We ensure a seamless experience from start to finish.</p>
          </div>
          <div className="feature-card">
            <div className="feature-image-container">
              <img 
                src="https://images.unsplash.com/photo-1585937421612-70a008356fbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YmlyeWFuaXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" 
                alt="Diverse Menu" 
                className="feature-image"
              />
            </div>
            <div className="feature-icon">
              <FaClipboardCheck />
            </div>
            <h3>Diverse Menu</h3>
            <p>From local delicacies to international gourmet dishes.</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta">
        <h2>Let Us Make Your Event Memorable</h2>
        <p>From intimate gatherings to grand celebrations, we cater to all your needs.</p>
        <Link to="/booking">
          <button className="btn cta-btn">Book Your Event</button>
        </Link>
      </section>
    </div>
  );
};

export default Home;