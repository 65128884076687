import React, { useState } from "react";
import './Contact.css';
import axios from "axios";
import config from '../../utils/config';

const Contact = () => {
  const [formData, setFormData] = useState({ 
    name: "", 
    email: "", 
    phone: "",
    subject: "",
    message: "" 
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    
    setLoading(true);
    setErrors({});
    
    try {
      const response = await axios.post(`${config.apiUrl}/api/contact`, formData);
      if (response.status === 200) {
        setStatusType("success");
        setStatusMessage("Thank you! Your message has been sent successfully.");
        setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
      }
    } catch (error) {
      console.error("Contact form submission error:", error);
      setStatusType("error");
      setStatusMessage("Failed to send message. Please try again or contact us directly.");
    } finally {
      setLoading(false);
      
      // Scroll to status message
      setTimeout(() => {
        const statusElement = document.querySelector('.status-message');
        if (statusElement) {
          statusElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-header">
        <h1>Get in Touch</h1>
        <p>We'd love to hear from you. Fill out the form below and we'll get back to you as soon as possible.</p>
      </div>
      
      <div className="contact-container">
        {/* Contact Form Card */}
        <div className="contact-form-container">
          <div className="contact-form-header">
            <h2>Send Us a Message</h2>
          </div>
          
          <div className="contact-form-content">
            {statusMessage && (
              <div className={`status-message ${statusType}`}>
                {statusMessage}
              </div>
            )}
            
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Your Name <span className="required">*</span></label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  className={errors.name ? 'error-input' : ''}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="email">Email Address <span className="required">*</span></label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    className={errors.email ? 'error-input' : ''}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
                
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                  />
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  value={formData.subject}
                  onChange={(e) => setFormData({...formData, subject: e.target.value})}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="message">Your Message <span className="required">*</span></label>
                <textarea
                  id="message"
                  rows="5"
                  value={formData.message}
                  onChange={(e) => setFormData({...formData, message: e.target.value})}
                  className={errors.message ? 'error-input' : ''}
                  placeholder="How can we help you?"
                ></textarea>
                {errors.message && <span className="error">{errors.message}</span>}
              </div>
              
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>
        
        {/* Contact Info Card */}
        <div className="contact-info">
          <div className="contact-info-header">
            <h2>Contact Information</h2>
          </div>
          
          <div className="contact-info-content">
            <div className="info-box">
              <div className="info-icon">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="info-content">
                <h3>Our Location</h3>
                <p>60, Mirbakshi Ali St, Bharathi Salai, Royapettah, <br />Chennai, Tamil Nadu 600014</p>
              </div>
            </div>
            
            <div className="info-box">
              <div className="info-icon">
                <i className="fas fa-phone-alt"></i>
              </div>
              <div className="info-content">
                <h3>Call Us</h3>
                <p>+91 98400 40641<br />+91 95000 80653</p>
              </div>
            </div>
            
            <div className="info-box">
              <div className="info-icon">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="info-content">
                <h3>Email Us</h3>
                <p>info@zubaidacaterers.com<br />bookings@zubaidacaterers.com</p>
              </div>
            </div>
            
            <div className="info-box">
              <div className="info-icon">
                <i className="fas fa-clock"></i>
              </div>
              <div className="info-content">
                <h3>Business Hours</h3>
                <p>Monday to Saturday: 10:00 AM - 8:00 PM<br />Sunday: Closed</p>
              </div>
            </div>
            
            <div className="social-media">
              <h3>Follow Us</h3>
              <div className="social-icons">
                <a href="#" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                <a href="#" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                <a href="#" aria-label="YouTube"><i className="fab fa-youtube"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8523034367396!2d80.26442147507825!3d13.050999587318392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52663f1e845cf3%3A0x9f1012ebec76154d!2s60%2C%20Mirbakshi%20Ali%20St%2C%20Bharathi%20Salai%2C%20Royapettah%2C%20Chennai%2C%20Tamil%20Nadu%20600014!5e0!3m2!1sen!2sin!4v1710400877611!5m2!1sen!2sin"
          width="100%" 
          height="350" 
          style={{ border: 0 }} 
          allowFullScreen 
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map to Zubaida Caterers"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;