import React from 'react';
import { Link } from 'react-router-dom';

const BookingSuccess = () => {
  return (
    <div className="success-container">
      <h2>Booking Successful!</h2>
      <p>Thank you for choosing Zubaida Caterers. You will receive a confirmation via email and WhatsApp shortly.</p>
      <Link to="/" className="home-btn">Return to Home</Link>
    </div>
  );
};

export default BookingSuccess;