import React, { useState } from "react";
import "./ImageGallery.css";

const ImageGallery = () => {
  const [activeCategory, setActiveCategory] = useState("all");
  
  const galleryImages = [
    {
      id: 1,
      url: "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZCUyMGluZGlhbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      category: "food",
      title: "Authentic Biryani"
    },
    {
      id: 2,
      url: "https://images.unsplash.com/photo-1542367592-8849eb970729?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8aW5kaWFuJTIwZm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      category: "food",
      title: "Traditional Thali"
    },
    {
      id: 3,
      url: "https://images.unsplash.com/photo-1601050690597-df0568f70950?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwc3dlZXRzfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
      category: "desserts",
      title: "Assorted Sweets"
    },
    {
      id: 4,
      url: "https://images.unsplash.com/photo-1630029144882-c79cd7881be0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8d2VkZGluZyUyMGNhdGVyaW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
      category: "events",
      title: "Wedding Setup"
    },
    {
      id: 5,
      url: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      category: "venue",
      title: "Restaurant Ambiance"
    },
    {
      id: 6,
      url: "https://images.unsplash.com/photo-1606491956689-2ea866880c84?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGluZGlhbiUyMGZvb2R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      category: "food",
      title: "Butter Chicken"
    },
    {
      id: 7,
      url: "https://images.unsplash.com/photo-1626082929543-5bdf3f8b1c67?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aW5kaWFuJTIwZGVzc2VydHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      category: "desserts",
      title: "Gulab Jamun"
    },
    {
      id: 8,
      url: "https://images.unsplash.com/photo-1591271955507-b3ef01ca4106?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29ycG9yYXRlJTIwZXZlbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      category: "events",
      title: "Corporate Event"
    },
    {
      id: 9,
      url: "https://images.unsplash.com/photo-1528605248644-14dd04022da1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHBhcnR5JTIwdmVudWV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      category: "venue",
      title: "Outdoor Venue"
    },
    {
      id: 10,
      url: "https://images.unsplash.com/photo-1464349095431-e9a21285b5f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJpcnRoZGF5JTIwcGFydHl8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      category: "events",
      title: "Birthday Celebration"
    },
    {
      id: 11,
      url: "https://images.unsplash.com/photo-1631452182959-930699d9a096?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW5kaWFuJTIwcmljZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      category: "food",
      title: "Fragrant Rice"
    },
    {
      id: 12,
      url: "https://images.unsplash.com/photo-1583532452513-a02186582ccd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2F0ZXJpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      category: "venue",
      title: "Catering Setup"
    }
  ];
  
  const categories = [
    { id: "all", name: "All" },
    { id: "food", name: "Food" },
    { id: "desserts", name: "Desserts" },
    { id: "events", name: "Events" },
    { id: "venue", name: "Venues" }
  ];
  
  const filteredImages = activeCategory === "all" 
    ? galleryImages 
    : galleryImages.filter(image => image.category === activeCategory);

  return (
    <div className="gallery-container">
      <div className="gallery-header">
        <h1>Our Gallery</h1>
        <p>Visual glimpses of our culinary creations and catering services</p>
      </div>
      
      <div className="gallery-filter">
        {categories.map(category => (
          <button 
            key={category.id}
            className={`filter-btn ${activeCategory === category.id ? "active" : ""}`}
            onClick={() => setActiveCategory(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
      
      <div className="gallery-grid">
        {filteredImages.map((image) => (
          <div key={image.id} className="gallery-item">
            <img src={image.url} alt={image.title} loading="lazy" />
            <div className="gallery-overlay">
              <div className="overlay-content">
                <h3>{image.title}</h3>
                <span className="category-tag">{categories.find(cat => cat.id === image.category).name}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;