// src/components/menu/MenuDisplay.js
import React, { useState } from "react";
import "../../styles/menudisplay.css";

const MenuDisplay = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  const menu = [
    {
      category: "Biryani Specials",
      items: [
        { name: "Chicken Biryani", price: 280, description: "Fragrant basmati rice cooked with tender chicken pieces and exotic spices", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2019/02/chicken-biryani-recipe.jpg" },
        { name: "Mutton Biryani", price: 350, description: "Slow-cooked aromatic rice with succulent mutton pieces and traditional spices", image: "https://www.licious.in/blog/wp-content/uploads/2022/06/mutton-biryani-recipe.jpg" },
        { name: "Veg Biryani", price: 220, description: "Fragrant rice with assorted vegetables and aromatic spices", image: "https://www.indianveggiedelight.com/wp-content/uploads/2020/04/veg-biryani-instant-pot.jpg" },
        { name: "Hyderabadi Biryani", price: 320, description: "Authentic Hyderabadi style biryani with layered rice and meat", image: "https://www.cubesnjuliennes.com/wp-content/uploads/2020/07/Chicken-Biryani-Recipe-500x500.jpg" }
      ],
    },
    {
      category: "Starters",
      items: [
        { name: "Chicken 65", price: 220, description: "Spicy, deep-fried chicken dish marinated with red chillies", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/chicken-65-recipe.jpg" },
        { name: "Paneer Tikka", price: 210, description: "Grilled cottage cheese cubes marinated in yogurt and spices", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2014/09/paneer-tikka-on-stove.jpg" },
        { name: "Veg Spring Rolls", price: 150, description: "Crispy rolls filled with sautéed vegetables", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/veg-spring-rolls-recipe.jpg" },
        { name: "Gobi Manchurian", price: 180, description: "Crispy cauliflower tossed in a spicy Indo-Chinese sauce", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/gobi-manchurian-recipe.jpg" }
      ],
    },
    {
      category: "Accompaniments",
      items: [
        { name: "Onion Raita", price: 60, description: "Cool yogurt with onions, perfect companion for biryani", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/06/onion-raita-recipe.jpg" },
        { name: "Brinjal Chutney", price: 80, description: "Tangy eggplant chutney with a blend of traditional spices", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2023/07/brinjal-chutney-recipe.jpg" },
        { name: "Green Salad", price: 50, description: "Fresh mix of cucumbers, tomatoes, and onions", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/02/vegetable-salad-recipe.jpg" },
        { name: "Papad Basket", price: 40, description: "Assorted crispy papads served with chutney", image: "https://static.toiimg.com/thumb/54567257.cms?width=1200&height=900" },
      ],
    },
    {
      category: "Desserts",
      items: [
        { name: "Bread ka Halwa", price: 120, description: "Sweet Indian pudding made from bread, milk, and nuts", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/10/bread-halwa-recipe.jpg" },
        { name: "Gulab Jamun", price: 90, description: "Soft milk solids balls soaked in rose-flavored sugar syrup", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/gulab-jamun-recipe-using-milk-powder.jpg" },
        { name: "Rasmalai", price: 100, description: "Soft cottage cheese dumplings in sweetened, thickened milk", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2023/04/rasmalai-recipe.jpg" },
        { name: "Kheer", price: 80, description: "Creamy rice pudding flavored with cardamom and saffron", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2014/11/rice-kheer-recipe.jpg" }
      ],
    },
    {
      category: "Beverages",
      items: [
        { name: "Masala Chai", price: 30, description: "Traditional Indian spiced tea", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2023/09/masala-chai-recipe.jpg" },
        { name: "Mango Lassi", price: 70, description: "Refreshing yogurt drink blended with mango pulp", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/04/mango-lassi-recipe.jpg" },
        { name: "Fresh Lime Soda", price: 50, description: "Refreshing lime soda - sweet, salt or mixed", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2021/05/nimbu-soda-lime-soda.jpg" },
        { name: "Buttermilk", price: 40, description: "Spiced yogurt drink with curry leaves and cumin", image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/chaas-recipe.jpg" }
      ],
    },
  ];

  const filteredMenu = activeCategory === "All" 
    ? menu 
    : menu.filter(section => section.category === activeCategory);

  return (
    <div className="menu-page-container">
      <div className="menu-header">
        <h1 className="menu-title">Our Culinary Delights</h1>
        <p className="menu-subtitle">Experience authentic flavors crafted with love and tradition</p>
      </div>
      
      <div className="menu-filter">
        <button 
          className={`filter-btn ${activeCategory === "All" ? "active" : ""}`}
          onClick={() => setActiveCategory("All")}
        >
          All
        </button>
        {menu.map((section, index) => (
          <button 
            key={index} 
            className={`filter-btn ${activeCategory === section.category ? "active" : ""}`}
            onClick={() => setActiveCategory(section.category)}
          >
            {section.category}
          </button>
        ))}
      </div>
      
      <div className="menu-content">
        {filteredMenu.map((section, index) => (
          <div key={index} className="menu-section">
            <h2 className="menu-category">{section.category}</h2>
            <div className="menu-items-grid">
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="menu-card">
                  <div className="menu-card-image" style={{ backgroundImage: `url(${item.image})` }}></div>
                  <div className="menu-card-content">
                    <div className="menu-card-header">
                      <h3 className="item-name">{item.name}</h3>
                      <span className="item-price">₹{item.price}</span>
                    </div>
                    <p className="item-description">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuDisplay;