import React, { createContext, useState, useContext } from 'react';

// Create a context for booking information
const BookingContext = createContext();

// Create a provider for the BookingContext
export const BookingProvider = ({ children }) => {
  const [bookingDetails, setBookingDetails] = useState({
    name: '',
    email: '',
    eventType: '',
    date: '',
    numberOfGuests: 0,
    menuSelections: [],
    specialRequests: ''
  });

  // Function to update booking details
  const updateBookingDetails = (newDetails) => {
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails
    }));
  };

  // Function to reset booking details (for example, after a successful booking)
  const resetBookingDetails = () => {
    setBookingDetails({
      name: '',
      email: '',
      eventType: '',
      date: '',
      numberOfGuests: 0,
      menuSelections: [],
      specialRequests: ''
    });
  };

  return (
    <BookingContext.Provider value={{ bookingDetails, updateBookingDetails, resetBookingDetails }}>
      {children}
    </BookingContext.Provider>
  );
};

// Custom hook to use the BookingContext
export const useBooking = () => {
  return useContext(BookingContext);
};