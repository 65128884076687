import React from "react";
import "./About.css";
import { FaHistory, FaUtensils, FaAward, FaHeart, FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import dadaImg from '../../assets/dadaimg.svg';
import abbaImg from '../../assets/abbaimg.svg';

const About = () => {
  return (
    <div className="about-container">
      {/* Hero Section */}
      <section className="about-hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Our Story</h1>
          <p>A Legacy of Culinary Excellence Since 1991</p>
        </div>
      </section>

      {/* Intro Section */}
      <section className="about-section intro">
        <div className="intro-grid">
          <div className="intro-content">
            <span className="section-subtitle">About Us</span>
            <h2 className="section-title">A Tradition of Exceptional Catering</h2>
            <p>Founded in 1991 by A.M. Dastagir baig, Zubaida Caterers began with a simple yet profound mission—to serve authentic and delicious food with love and care. Over the years, this passion for hospitality transformed into a full-fledged catering service, passed down through generations.</p>
            <p>Today, under the leadership of Muhammed Ameen Bin Yacoob, we continue our commitment to delivering exceptional catering experiences for all occasions, maintaining the same values of quality, tradition, and customer satisfaction.</p>
          </div>
          <div className="intro-image">
            <img src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60" alt="Zubaida Caterers Restaurant" />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="about-section features-section">
        <span className="section-subtitle">Why Choose Us</span>
        <h2 className="section-title">What Sets Us Apart</h2>
        
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">
              <FaUtensils />
            </div>
            <h3>Quality Ingredients</h3>
            <p>We source the freshest ingredients daily to ensure authentic flavors and exceptional taste in every dish we prepare.</p>
          </div>
          
          <div className="feature-card">
            <div className="feature-icon">
              <FaAward />
            </div>
            <h3>Signature Biryani</h3>
            <p>Our special biryani recipe has earned us a reputation as the top choice for biryani lovers across the region.</p>
          </div>
          
          <div className="feature-card">
            <div className="feature-icon">
              <FaHeart />
            </div>
            <h3>Personalized Service</h3>
            <p>We work closely with our clients to tailor our services to their specific needs, ensuring unforgettable events.</p>
          </div>
          
          <div className="feature-card">
            <div className="feature-icon">
              <FaGlobe />
            </div>
            <h3>Diverse Menu</h3>
            <p>From traditional favorites to innovative creations, our diverse menu caters to all tastes and preferences.</p>
          </div>
        </div>
      </section>

      {/* Journey Section */}
      <section className="about-section journey-section">
        <div className="journey-grid">
          <div className="journey-image">
            <img src="https://images.unsplash.com/photo-1514326640560-7d063ef2aed5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwZm9vZHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60" alt="Our Culinary Journey" />
          </div>
          <div className="journey-content">
            <span className="section-subtitle">Our Journey</span>
            <h2 className="section-title">From Small Beginnings to Culinary Excellence</h2>
            
            <div className="timeline">
              <div className="timeline-item">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <h3>1991</h3>
                  <p>Founded by A.M. Dastagir baig with a vision to serve authentic flavors.</p>
                </div>
              </div>
              
              <div className="timeline-item">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <h3>2005</h3>
                  <p>Expanded services to include corporate events and large-scale weddings.</p>
                </div>
              </div>
              
              <div className="timeline-item">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <h3>2015</h3>
                  <p>Leadership transition to Muhammed Ameen Bin Yacoob, bringing new innovations.</p>
                </div>
              </div>
              
              <div className="timeline-item">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <h3>Today</h3>
                  <p>Continuing our legacy of excellence with modern approaches and timeless flavors.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section - Modern Grid Layout */}
      <section className="about-section team-section">
        <span className="section-subtitle">Our Leadership</span>
        <h2 className="section-title">The Team Behind Our Success</h2>
        
        <div className="team-grid">
          <div className="team-card">
            <div className="team-image">
              <img src={dadaImg} alt="A.M. Dastagir Baig" />
            </div>
            <div className="team-info">
              <h3>A.M. Dastagir Baig</h3>
              <p className="team-role">Founder</p>
              <p className="team-bio">Started with a vision to bring authentic flavors to every table, creating a legacy of culinary excellence.</p>
            </div>
          </div>
          
          <div className="team-card">
            <div className="team-image">
              <img src={abbaImg} alt="D. Yacoob Baig" />
            </div>
            <div className="team-info">
              <h3>D. Yacoob Baig</h3>
              <p className="team-role">Chairman</p>
              <p className="team-bio">Expanded our operations while maintaining the same commitment to quality and customer satisfaction.</p>
            </div>
          </div>
          
          <div className="team-card">
            <div className="team-image">
              <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGJ1c2luZXNzJTIwbWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="Muhammed Ameen Bin Yacoob" />
            </div>
            <div className="team-info">
              <h3>Muhammed Ameen Bin Yacoob</h3>
              <p className="team-role">CEO</p>
              <p className="team-bio">Leading us into the future with innovative approaches while honoring our rich culinary heritage.</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="about-section cta-section">
        <div className="cta-content">
          <h2>Ready to Create Unforgettable Experiences?</h2>
          <p>Let us cater your next event with our exceptional service and delicious cuisine.</p>
          <Link to="/booking" className="cta-button">Book Your Event</Link>
        </div>
      </section>
    </div>
  );
};

export default About;