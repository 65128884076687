export const validateForm = (formData) => {
  let errors = {};

  if (!formData.name.trim()) {
    errors.name = 'Name is required';
  }

  if (!formData.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = 'Invalid email format';
  }

  if (!formData.phone.trim()) {
    errors.phone = 'Phone number is required';
  }

  if (!formData.eventType) {
    errors.eventType = 'Please select an event type';
  }

  if (!formData.date) {
    errors.date = 'Event date is required';
  }

  if (!formData.numberOfGuests) {
    errors.numberOfGuests = 'Number of guests is required';
  } else if (formData.numberOfGuests < 1) {
    errors.numberOfGuests = 'Number of guests must be at least 1';
  }

  return errors;
};