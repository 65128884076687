// src/components/Pages/Services.js
import React from "react";
import "../../styles/Services.css";
import { Link } from "react-router-dom";
import { FaUtensils, FaBirthdayCake, FaBuilding, FaGlassCheers, FaUsers, FaHeart } from "react-icons/fa";

const Services = () => {
  return (
    <div className="services-container">
      {/* Hero Section */}
      <div className="services-hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Our Services</h1>
          <p>Exceptional catering for every occasion</p>
        </div>
      </div>
      
      {/* Services Introduction */}
      <div className="services-intro">
        <h2>Crafting Unforgettable Culinary Experiences</h2>
        <p>
          At Zubaida Caterers, we understand that every event is unique. We provide bespoke catering 
          services tailored to your specific needs, ensuring your special occasions are complemented 
          with delicious food and impeccable service.
        </p>
      </div>
      
      {/* Main Services Grid */}
      <div className="services-grid">
        <div className="service-card">
          <div className="service-image">
            <img src="https://images.unsplash.com/photo-1511795409834-ef04bbd61622?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d2VkZGluZyUyMGNhdGVyaW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" alt="Wedding Catering" />
          </div>
          <div className="service-content">
            <div className="service-icon">
              <FaGlassCheers />
            </div>
            <h3>Wedding Celebrations</h3>
            <p>Make your special day truly memorable with our customized wedding catering services. From intimate gatherings to grand celebrations, we handle everything with care and attention to detail.</p>
            <ul className="service-features">
              <li>Customized menu planning</li>
              <li>Elegant presentation</li>
              <li>Professional service staff</li>
              <li>Special dietary accommodations</li>
            </ul>
          </div>
        </div>
        
        <div className="service-card">
          <div className="service-image">
            <img src="https://images.unsplash.com/photo-1588075592446-265bad68be25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y29ycG9yYXRlJTIwZXZlbnQlMjBmb29kfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" alt="Corporate Events" />
          </div>
          <div className="service-content">
            <div className="service-icon">
              <FaBuilding />
            </div>
            <h3>Corporate Events</h3>
            <p>Impress your clients and reward your team with professional catering services for all your corporate gatherings, from business lunches to annual celebrations.</p>
            <ul className="service-features">
              <li>Flexible catering packages</li>
              <li>On-time delivery</li>
              <li>Professional presentation</li>
              <li>Customized branding options</li>
            </ul>
          </div>
        </div>
        
        <div className="service-card">
          <div className="service-image">
            <img src="https://images.unsplash.com/photo-1464349095431-e9a21285b5f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJpcnRoZGF5JTIwcGFydHl8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60" alt="Birthday Celebrations" />
          </div>
          <div className="service-content">
            <div className="service-icon">
              <FaBirthdayCake />
            </div>
            <h3>Birthday Celebrations</h3>
            <p>Make your birthday celebration special with our delicious food and themed catering options. We create memorable experiences for birthdays of all ages.</p>
            <ul className="service-features">
              <li>Theme-based menu options</li>
              <li>Custom birthday cakes</li>
              <li>Decorative food presentations</li>
              <li>Kid-friendly menu options</li>
            </ul>
          </div>
        </div>
        
        <div className="service-card">
          <div className="service-image">
            <img src="https://images.unsplash.com/photo-1529566652340-2c41a1eb6d93?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGZhbWlseSUyMGRpbm5lcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60" alt="Private Functions" />
          </div>
          <div className="service-content">
            <div className="service-icon">
              <FaUsers />
            </div>
            <h3>Private Functions</h3>
            <p>Enjoy your intimate gatherings without the stress of cooking. We provide personalized catering for family reunions, anniversaries, and special occasions.</p>
            <ul className="service-features">
              <li>Intimate dining experiences</li>
              <li>Family-style serving options</li>
              <li>Personalized menu consultation</li>
              <li>Home delivery and setup</li>
            </ul>
          </div>
        </div>
      </div>
      
      {/* Additional Services */}
      <div className="additional-services">
        <h2>Additional Offerings</h2>
        <div className="additional-grid">
          <div className="additional-card">
            <div className="additional-icon">
              <FaUtensils />
            </div>
            <h3>Specialized Cuisine</h3>
            <p>From traditional Hyderabadi delicacies to international cuisine, our talented chefs can create a diverse menu to suit your preferences.</p>
          </div>
          
          <div className="additional-card">
            <div className="additional-icon">
              <FaHeart />
            </div>
            <h3>Dietary Accommodations</h3>
            <p>We cater to various dietary requirements including vegetarian, vegan, gluten-free, and allergy-sensitive options.</p>
          </div>
        </div>
      </div>
      
      {/* Call to Action */}
      <div className="services-cta">
        <h2>Ready to Plan Your Event?</h2>
        <p>Contact us today to discuss your catering needs and receive a customized quote.</p>
        <div className="cta-buttons">
          <Link to="/booking" className="cta-button primary">Book Now</Link>
          <Link to="/contact" className="cta-button secondary">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default Services;