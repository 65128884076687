import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-title">Zubaida Caterers</h3>
          <p className="footer-description">
            Exceptional catering for your special occasions.
          </p>
          <div className="social-icons">
            <a href="#" className="social-icon" aria-label="Facebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="social-icon" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="social-icon" aria-label="Twitter">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
        
        <div className="footer-section">
          <h3 className="footer-title">Quick Links</h3>
          <div className="footer-links-grid">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/menu">Menu</Link>
            <Link to="/booking">Book Now</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        
        <div className="footer-section">
          <h3 className="footer-title">Contact Us</h3>
          <p className="contact-info mini"><i className="fas fa-phone"></i> +91 98765 43210</p>
          <p className="contact-info mini"><i className="fas fa-envelope"></i> info@zubaidacaterers.com</p>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Zubaida Caterers. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;