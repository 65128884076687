import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { BookingProvider } from './context/BookingContext'; // Provides booking-related state and actions
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import Services from "./components/Pages/Services";
import MenuDisplay from "./components/Menu/MenuDisplay";
import BookingForm from "./components/Booking/BookingForm";
import ImageGallery from "./components/Gallery/ImageGallery";
import './styles/global.css'; // Global styles

// ScrollToTop component to reset scroll position on page change
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}

const App = () => {
  return (
    <BookingProvider>
      <Router>
        {/* Navbar: Displayed on all pages */}
        <Navbar />
        
        {/* ScrollToTop component to reset scroll position */}
        <ScrollToTop />

        {/* Main Content */}
        <main className="min-h-screen">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/menu" element={<MenuDisplay />} />
            <Route path="/booking" element={<BookingForm />} />
            <Route path="/gallery" element={<ImageGallery />} />
          </Routes>
        </main>

        {/* Footer: Displayed on all pages */}
        <Footer />
      </Router>
    </BookingProvider>
  );
};

export default App;