import React from 'react';
import { Link } from 'react-router-dom'; // For React Router links
import '../../styles/navbar.css';  // Correct relative path


const Navbar = () => {
    return (
      <nav>
        <div className="navbar-header">
          <h1 className="logo">Zubaida Caterers</h1> {/* Your website name or logo */}
        </div>
        <div className="navbar-menu">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/menu">Menu</Link></li>
            <li><Link to="/booking">Booking</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </nav>
    );
  };
  
  export default Navbar;