import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { validateForm } from './BookingValidation';
import BookingSuccess from './BookingSuccess';
import './BookingForm.css';
import config from '../../utils/config';

const BookingForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    eventType: '',
    date: '',
    time: '',
    numberOfGuests: '',
    menuSelections: [],
    specialRequests: '',
    address: '',
    city: ''
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  // Get tomorrow's date for minimum date selection
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().split('T')[0];

  // Available event types
  const eventTypes = [
    'Wedding Reception',
    'Corporate Event',
    'Birthday Party',
    'Anniversary',
    'Family Gathering',
    'Religious Ceremony',
    'Other'
  ];

  // Available menu selections
  const menuOptions = [
    'Chicken Biryani',
    'Mutton Biryani',
    'Veg Biryani',
    'Chicken 65',
    'Paneer Tikka',
    'Onion Raita',
    'Brinjal Chutney',
    'Bread ka Halwa',
    'Gulab Jamun'
  ];

  // Handle checkbox changes for menu selections
  const handleMenuSelection = (item) => {
    const currentSelections = [...formData.menuSelections];
    if (currentSelections.includes(item)) {
      setFormData({
        ...formData,
        menuSelections: currentSelections.filter(selection => selection !== item)
      });
    } else {
      setFormData({
        ...formData,
        menuSelections: [...currentSelections, item]
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      setServerError('');
      
      try {
        // First, check server connectivity
        const pingResponse = await axios.get(`${config.apiUrl}`);
        
        // If server is reachable, proceed with the booking
        const response = await axios.post(`${config.apiUrl}/api/bookings`, formData, {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 10000 // 10 second timeout
        });
        
        if (response.status === 200) {
          setIsSubmitted(true);
          setFormData({
            name: '',
            email: '',
            phone: '',
            eventType: '',
            date: '',
            time: '',
            numberOfGuests: '',
            menuSelections: [],
            specialRequests: '',
            address: '',
            city: ''
          });
        }
      } catch (error) {
        console.error("Booking error:", error);
        
        if (error.code === 'ECONNABORTED') {
          setServerError('Request timed out. Please try again later.');
        } else if (error.response) {
          // The server responded with a status code outside of 2xx range
          setServerError(error.response.data?.message || `Error: ${error.response.status} - ${error.response.statusText}`);
        } else if (error.request) {
          // The request was made but no response was received
          setServerError('Could not connect to the server. Please check your internet connection or try again later.');
        } else {
          // Something happened in setting up the request
          setServerError('An unexpected error occurred. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
      // Scroll to the first error
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  if (isSubmitted) {
    return <BookingSuccess />;
  }

  return (
    <div className="booking-container">
      <div className="booking-header">
        <h2>Book Your Event</h2>
        <p>Let us make your special occasion a memorable one. Fill in the details below to get started.</p>
      </div>

      {serverError && (
        <div className="server-error">
          <p>{serverError}</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="booking-form">
        <div className="form-section">
          <h3>Personal Information</h3>
          
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Full Name <span className="required">*</span></label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                className={errors.name ? 'error-input' : ''}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address <span className="required">*</span></label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                className={errors.email ? 'error-input' : ''}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="phone">Phone Number <span className="required">*</span></label>
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                className={errors.phone ? 'error-input' : ''}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                value={formData.address}
                onChange={(e) => setFormData({...formData, address: e.target.value})}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              value={formData.city}
              onChange={(e) => setFormData({...formData, city: e.target.value})}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>Event Details</h3>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="eventType">Event Type <span className="required">*</span></label>
              <select
                id="eventType"
                value={formData.eventType}
                onChange={(e) => setFormData({...formData, eventType: e.target.value})}
                className={errors.eventType ? 'error-input' : ''}
              >
                <option value="">Select Event Type</option>
                {eventTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
              {errors.eventType && <span className="error">{errors.eventType}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="numberOfGuests">Number of Guests <span className="required">*</span></label>
              <input
                type="number"
                id="numberOfGuests"
                min="10"
                value={formData.numberOfGuests}
                onChange={(e) => setFormData({...formData, numberOfGuests: e.target.value})}
                className={errors.numberOfGuests ? 'error-input' : ''}
              />
              {errors.numberOfGuests && <span className="error">{errors.numberOfGuests}</span>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Event Date <span className="required">*</span></label>
              <input
                type="date"
                id="date"
                min={minDate}
                value={formData.date}
                onChange={(e) => setFormData({...formData, date: e.target.value})}
                className={errors.date ? 'error-input' : ''}
              />
              {errors.date && <span className="error">{errors.date}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="time">Event Time <span className="required">*</span></label>
              <input
                type="time"
                id="time"
                value={formData.time}
                onChange={(e) => setFormData({...formData, time: e.target.value})}
                className={errors.time ? 'error-input' : ''}
              />
              {errors.time && <span className="error">{errors.time}</span>}
            </div>
          </div>
        </div>

        <div className="form-section">
          <h3>Menu Selection</h3>
          <p className="section-description">Choose the items you'd like to include in your event menu:</p>
          
          <div className="menu-selection">
            {menuOptions.map((item, index) => (
              <div key={index} className="menu-option">
                <input
                  type="checkbox"
                  id={`menu-${index}`}
                  checked={formData.menuSelections.includes(item)}
                  onChange={() => handleMenuSelection(item)}
                />
                <label htmlFor={`menu-${index}`}>{item}</label>
              </div>
            ))}
          </div>
          {errors.menuSelections && <span className="error">{errors.menuSelections}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="specialRequests">Special Requests or Notes</label>
          <textarea
            id="specialRequests"
            rows="4"
            value={formData.specialRequests}
            onChange={(e) => setFormData({...formData, specialRequests: e.target.value})}
            placeholder="Any special requirements, dietary restrictions, or additional information..."
          ></textarea>
        </div>

        <button 
          type="submit" 
          className="submit-btn" 
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Book Your Event'}
        </button>
      </form>
    </div>
  );
};

export default BookingForm;